import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/cody/chat": [22],
		"/search": [23],
		"/[...repo=reporev]/(validrev)/(code)/-/blob/[...path]": [9,[2,4,5],[3]],
		"/[...repo=reporev]/-/branches": [13,[2,6],[3]],
		"/[...repo=reporev]/-/branches/all": [14,[2,6],[3]],
		"/[...repo=reporev]/(validrev)/-/changelists/[...path]": [11,[2,4],[3]],
		"/[...repo=reporev]/-/changelist/[changelistID]": [15,[2],[3]],
		"/[...repo=reporev]/(validrev)/-/commits/[...path]": [12,[2,4],[3]],
		"/[...repo=reporev]/-/commit/[...revspec]": [16,[2],[3]],
		"/[...repo=reporev]/-/compare/[...rangeSpec]": [17,[2],[3]],
		"/[...repo=reporev]/-/labels": [18,[2],[3]],
		"/[...repo=reporev]/-/stats/contributors": [19,[2],[3]],
		"/[...repo=reporev]/-/tags": [20,[2],[3]],
		"/[...repo=reporev]/(validrev)/(code)/-/tree/[...path]": [10,[2,4,5],[3]],
		"/[community=communitySearchContext]": [21],
		"/[...repo=reporev]/(validrev)/(code)": [8,[2,4,5],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';